import React, {Component} from "react";

export default class Amanah extends Component {
    render() {
        return (
            <section className="portfolio hide">
                <div className="portfolio-item-wrapper portfolio-custom">
                    <div className="portfolio-content">
                        <h2 className="section-title title">AMANAH</h2>
                        <p className="section-des">
                            Rice Field Management Application to Optimize Land Planning and Agricultural Irrigation
                        </p>
                        <p className="content-670">
                            The Amanah application is an application that aims to assist farmers in optimizing crop
                            planting by calculating the spacing of plants and the area of rice fields, as well as
                            irrigation planning by calculating irrigation water needs (IN) based on predictions of
                            groundwater balance on the agricultural land.
                        </p>
                        <div className="image-slider-wrapper relative block-right">
                            <div className="owl-carousel owl-theme image-slider slider" data-speed={2000}
                                 data-auto="false" data-hover="true">
                                <div className="owl-item">
                                    <img src="images/amanah-1-big.jpg" alt=""/>
                                </div>
                                <div className="owl-item">
                                    <img src="images/amanah-2-big.jpg" alt=""/>
                                </div>
                                <div className="owl-item">
                                    <img src="images/amanah-3-big.jpg" alt=""/>
                                </div>
                            </div>
                        </div>
                        <ul className="tech-used tech-used-bar about-info">
                            <li>
                                <h2 className="section-des">Technology used</h2>
                            </li>
                            <li className="skill-list">
                                <span className="name">FIGMA</span>
                                <div className="skill">
                                    <div className="skill-fill" data-fill="100%"/>
                                </div>
                            </li>
                            <li className="skill-list">
                                <span className="name">AFFINITY DESIGNER</span>
                                <div className="skill">
                                    <div className="skill-fill" data-fill="60%"/>
                                </div>
                            </li>
                        </ul>
                        <div className="content-670">
                            <p>In crop planting planning, farmers can draw rice fields on a Google Maps digital map and
                                the application will automatically calculate the area of land and the number of plants
                                that should be planted in that area. The number of plants that must be planted is
                                closely related to the spacing of the plants and greatly affects the availability of
                                water and other substances in the soil. From the type of crop and the calculation of the
                                number of plants, the application can estimate when the crop will be harvested and how
                                many plants will be harvested (for example, 4 tons of rice). Not only that, by entering
                                the current market price, the application will also calculate the estimated gross income
                                from the crop.</p>
                            <p>This product is a UI Mockup. Unfortunately the application development is ceased due to
                                COVID-19. The application is competing at one of application competition in Malaysia,
                                but the team can't continue any further and thus ceased the development.</p>
                            {/*<div className="portfolio-see-more">*/}
                            {/*    <Link to="#">SEE MORE</Link>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}