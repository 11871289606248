import React, {Component} from "react";

export default class OpenMind extends Component {
    render() {
        return (
            <section className="portfolio hide">
                <div className="portfolio-item-wrapper portfolio-custom">
                    <div className="portfolio-content">
                        <h2 className="section-title title">OPENMIND</h2>
                        <p className="section-des">
                            A Trevu Addons - a feature that will accompany us to teach the culture of
                            our local area.
                        </p>
                        <p className="content-670">
                            The purpose of this application is to make it easier for Muslim tourists, especially
                            Indonesian tourists, to travel in a country that has a culture that is different from the
                            culture where they come from without having to be afraid of making mistakes and leaving bad
                            marks on the name of Indonesian tourists.
                        </p>
                        <div className="image-slider-wrapper relative block-right">
                            <div className="owl-carousel owl-theme image-slider slider" data-speed={2000}
                                 data-auto="false" data-hover="true">
                                <div className="owl-item">
                                    <img src="images/openmind-2.jpg" alt=""/>
                                </div>
                                <div className="owl-item">
                                    <img src="images/openmind-1.jpg" alt=""/>
                                </div>
                            </div>
                        </div>
                        <ul className="tech-used tech-used-bar about-info">
                            <li>
                                <h2 className="section-des">Technology used</h2>
                            </li>
                            <li className="skill-list">
                                <span className="name">FIGMA</span>
                                <div className="skill">
                                    <div className="skill-fill" data-fill="100%"/>
                                </div>
                            </li>
                            <li className="skill-list">
                                <span className="name">ADOBE ILLUSTRATOR</span>
                                <div className="skill">
                                    <div className="skill-fill" data-fill="40%"/>
                                </div>
                            </li>
                            <li className="skill-list">
                                <span className="name">AFFINITY DESIGNER</span>
                                <div className="skill">
                                    <div className="skill-fill" data-fill="60%"/>
                                </div>
                            </li>
                        </ul>
                        <div className="content-670">
                            <p>Most of the trip includes blending the culture and customs of the destination. Getting
                                out
                                of our comfort zone and living like locals is a goal we want to achieve during our
                                journey.
                                As a Muslim Traveler, we often find the culture of a place that we don't know about
                                causing
                                us to leave a bad impression on outside tourists.
                            </p>

                            <p>The current progress is still on development, since the main application has some issue
                                that need to be resolved first. It's made with Figma with illustration made with
                                Affinity Designer and Adobe Illustrator. This product featured as one of the finalists
                                on ITFEST
                                USU 2020.
                            </p>
                            {/*<div className="portfolio-see-more disabled">*/}
                            {/*    <Link to="#">SEE MORE</Link>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}