import React, {Component} from "react";

export default class Trevu extends Component {
    render() {
        return (
            <section className="portfolio hide">
                <div className="portfolio-item-wrapper portfolio-custom">
                    <div className="portfolio-content">
                        <h2 className="section-title title">TREVU</h2>
                        <p className="section-des">
                            Trevu allows Muslim to travel with ease without needed to set aside their Shari’a.
                        </p>
                        <p className="content-670">
                            The world of tourism is a very rapidly developing and promising field. It is proven with the
                            increasing number of Muslim who travel around the world. In this era, a beauty of tourist
                            attraction can be various from the culture to culinary. Despite the beautiful culture and
                            culinary, it is hard for a Muslim who travel to a place that is non-Muslim majority. It is
                            hard to find such as halal food and prayer room. Worrying trivial things will make our
                            journey less enjoyable. Thus, Trevu is created. Trevu is an all-in-one solution for a Muslim
                            need when traveling into another country.
                        </p>
                        <div className="image-slider-wrapper relative block-right">
                            <div className="owl-carousel owl-theme image-slider slider" data-speed={2000}
                                 data-auto="false" data-hover="true">
                                <div className="owl-item">
                                    <img src="images/trevu-1.jpg" alt=""/>
                                </div>
                                <div className="owl-item">
                                    <img src="images/trevu-2.jpg" alt=""/>
                                </div>
                            </div>
                        </div>
                        <ul className="tech-used tech-used-bar about-info">
                            <li>
                                <h2 className="section-des">Technology used</h2>
                            </li>
                            <li className="skill-list">
                                <span className="name">JAVA</span>
                                <div className="skill">
                                    <div className="skill-fill" data-fill="100%"/>
                                </div>
                            </li>
                            <li>
                                <span className="name">ADOBE XD</span>
                                <div className="skill">
                                    <div className="skill-fill" data-fill="100%"/>
                                </div>
                            </li>
                        </ul>
                        <div className="content-670">
                            <p>Trevu is a Muslim traveling mate application that based on community review. With the
                                Halal Trip evolving into 2.0 which targeting at Muslim interaction, we make an
                                application that based on Muslim interaction at each other. Trevu main features are
                                Prayer, Food and Gift, Tourism Attraction, Travel Dictionary, and Find Muslim Friends.
                                Prayer features allow our fellow Muslim to find nearby mosque, salat time, and qibla
                                direction. Food and Gift gives recommendation about nearby halal food and gift, and also
                                checking one restaurant Halal certificate. Tourism Attraction gives recommendation about
                                tourist destination at user’s country. All those things supported by Travel Dictionary
                                which Is a dictionary that made by community that can give translation of frequently
                                asked question by traveler. The app can also find us friends so that we can travel with
                                fellow Muslim friends. All of those things can be obtained real-time and community
                                based, which means that you can give review and rating.
                            </p>
                            <p>
                                Trevu is developed with Java programming language, and use Firebase as it's database.
                                The application using Google Maps API for it's nearby data and using self-made algorithm
                                to determine recommendation per user. The application is completed, but there is an
                                issue that need to be resolved before deploying it into Play Store.
                            </p>
                            {/*<div className="portfolio-see-more">*/}
                            {/*    <Link to="#">SEE MORE</Link>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}