import React, {Component} from 'react';
import Header from "./components/Header";
import Services from "./components/Services";
import About from "./components/About";
import Contact from "./components/Contact";
import Skills from "./components/Skills";
import Home from "./components/Home";
import Portfolio from "./components/Portfolio";
import ScriptTag from 'react-script-tag';
import TrackRecords from "./components/TrackRecords";
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Amanah from "./components/Amanah";
import Iustia from "./components/Iustia";
import OpenMind from "./components/OpenMind";
import Trevu from "./components/Trevu";

class App extends Component {
    render() {
        return (
            <Router>
                <body id="smooth-scroll" className="one-page">

                {/*<div className="doc-loader"/>*/}

                <div className="container">
                    <div className="page-wrapper">
                        <Header/>
                        <Home/>
                        <About/>
                        <Services/>
                        <Portfolio/>
                        <Switch>
                            <Route path="/amanah" exact component={Amanah}/>
                            <Route path="/iustia" exact component={Iustia}/>
                            <Route path="/openmind" exact component={OpenMind}/>
                            <Route path="/trevu" exact component={Trevu}/>
                        </Switch>
                        <TrackRecords/>
                        <Skills/>
                        <Contact/>
                    </div>
                </div>
                <ScriptTag src="./js/smoothscroll.js"/>
                <ScriptTag src="./js/main.js"/>
                </body>
            </Router>
        )

    }

}

export default App;
