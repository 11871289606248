import React, {Component} from "react";

export default class Home extends Component {
    render() {
        return (
            <section id="home" className="section">
                <div className="container-wrap">
                    <h1 className="entry-title" data-jarallax-element="0 30">Hello I’m <span>Alfredo Juan</span>
                    </h1>
                    <img className="main-photo block-right" data-jarallax-element="0 -30" src=""/>
                    <a href="#about" className="main-btn slow-scroll"><img src="images/icons/scroll-icon.svg" alt=""/></a>
                </div>
            </section>
        )
    }
}