import React, {Component} from "react";

export default class Iustia extends Component {
    render() {
        return (
            <section className="portfolio hide">
                <div className="portfolio-item-wrapper portfolio-custom">
                    <div className="portfolio-content">
                        <h2 className="section-title title">IUSTIA</h2>
                        <p className="section-des">
                            Online test android application with alternative advanced cheating detection.
                        </p>
                        <p className="content-670">
                            Iustia is an online test application that can detect cheating attempt by using 4 sensors.
                            Iustia used Context-aware concept by using Gyroscope, Light sensor, Microphone, and GPS to
                            determine whether the participant is cheating or not. Iustia made with the heavy
                            consideration with privacy, since online proctor usually using camera and that is
                            problematic especially with privacy concern.
                        </p>
                        <div className="image-slider-wrapper relative block-right">
                            <div className="owl-carousel owl-theme image-slider slider" data-speed={2000}
                                 data-auto="false" data-hover="true">
                                <div className="owl-item">
                                    <img src="images/iustia-1.jpg" alt=""/>
                                </div>
                                <div className="owl-item">
                                    <img src="images/iustia-2.jpg" alt=""/>
                                </div>
                                <div className="owl-item">
                                    <img src="images/iustia-3.jpg" alt=""/>
                                </div>
                            </div>
                        </div>
                        <ul className="tech-used tech-used-bar about-info">
                            <li>
                                <h2 className="section-des">Technology used</h2>
                            </li>
                            <li className="skill-list">
                                <span className="name">JAVA</span>
                                <div className="skill">
                                    <div className="skill-fill" data-fill="100%"/>
                                </div>
                            </li>
                            <li className="skill-list">
                                <span className="name">FIGMA</span>
                                <div className="skill">
                                    <div className="skill-fill" data-fill="100%"/>
                                </div>
                            </li>
                        </ul>
                        <div className="content-670">
                            <p>Iustia developed with Java programming language. Database used are Firebase Database. The
                                UI is
                                created in Figma. The application can pretty much do the same like the others online
                                test platform. The application is done and is in progress on releasing it on Google Play
                                Store.</p>
                            {/*<div className="portfolio-see-more">*/}
                            {/*    <Link to="#">SEE MORE</Link>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}