import React, {Component} from "react";

export default class Contact extends Component {
    render() {
        return (
            <section id="contact" className="section">
                <h2 className="title">CONTACT</h2>
                <div className="section-des">
                    You know, we probably gonna be great if we work together.
                </div>
                <div className="content-670">
                    <p>
                        Interested in hiring me? Contact me from one of these information. I will answer as fast as I
                        can.
                    </p>
                </div>
                <div className="content-670">
                    <div className="contact-form">
                        <ul className="serv-link-cover block-right" data-jarallax-element="0 40">
                            <li>
                                <h6>EMAIL</h6>
                                <a href="mailto:hello@alfrdjuan.com">hello@alfrdjuan.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="content-670">
                    <p>
                        <br/>
                        Thank you for reading until this part. I hope I can see you again sometimes!</p>
                </div>
            </section>
        )
    }
}