import React, {Component} from "react";

export default class Services extends Component {
    render() {
        return (
            <section id="services" className="section">
                <div className="content-670">
                    <h2 className="title">SERVICES</h2>
                    <div className="section-des">
                        I create beautiful UI design for various purpose and turn it into real application using
                        "magic" (aka codes).
                    </div>
                    <p>
                        I can create beautiful design using various application such as Figma and Adobe XD. I create lot
                        of stuff for various purpose. I've dived UI Design since I'm at third semester (approximately 2
                        years ago).
                    </p>
                    <p>
                        Designing an interesting application is fun, but for me it's just not enough. It got me thinking
                        "What if I can make all of my design into reality?" and from then I started to learn various
                        language and dive into code world.
                    </p>
                </div>
                <div className="content-670">
                    <div className="serv-block-wrap">
                        <div className="serv-block-item">
                            <img src="images/icons/design-tools.svg" alt=""/>
                            <h4>DESIGN</h4>
                            <p>
                                Design User Interface for mobile application with Figma and Adobe XD.
                            </p>
                        </div>
                        <div className="serv-block-item">
                            <img src="images/icons/coding.svg" alt=""/>
                            <h4>DEVELOP</h4>
                            <p>
                                Develops Android application with Java / Kotlin.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}