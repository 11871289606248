import React, {Component} from "react";

export default class Skills extends Component {
    render() {
        return (
            <section id="skills" className="section">
                <h2 className="title">SKILLS</h2>
                <div className="section-des">
                    “I like to reinvent myself — it’s part of my job.” - Karl Lagerfield
                </div>
                <p className="content-670">
                    Programming and User Interface Designing skills.
                </p>
                <ul className="skills-progress">
                    <li>
                        <span className="name">JAVA</span>
                        <div className="skill">
                            <div className="skill-fill" data-fill="80%"/>
                        </div>
                    </li>
                    <li>
                        <span className="name">ADOBE XD</span>
                        <div className="skill">
                            <div className="skill-fill" data-fill="80%"/>
                        </div>
                    </li>
                    <li>
                        <span className="name">KOTLIN</span>
                        <div className="skill">
                            <div className="skill-fill" data-fill="50%"/>
                        </div>
                    </li>
                    <li>
                        <span className="name">FIGMA</span>
                        <div className="skill">
                            <div className="skill-fill" data-fill="90%"/>
                        </div>
                    </li>
                    <li>
                        <span className="name">REACT JS</span>
                        <div className="skill">
                            <div className="skill-fill" data-fill="10%"/>
                        </div>
                    </li>
                </ul>
                <br/>
                <p className="content-670">
                    Miscellaneous skills
                </p>
                <ul className="skills-progress">
                    <li>
                        <span className="name">ENGLISH</span>
                        <div className="skill">
                            <div className="skill-fill" data-fill="82%"/>
                        </div>
                    </li>
                    <li>
                        <span className="name">BAHASA</span>
                        <div className="skill">
                            <div className="skill-fill" data-fill="90%"/>
                        </div>
                    </li>
                    <li>
                        <span className="name">GRAPHIC DESIGN</span>
                        <div className="skill">
                            <div className="skill-fill" data-fill="50%"/>
                        </div>
                    </li>
                </ul>
            </section>
        )
    }
}