import React, {Component} from 'react'

export default class Header extends Component {
    render() {
        return (
            <div>
                <div id="nav-btn" className="nav-btn">
                    <span className="nav-btn-cover">
                        <span className="menu-line"/>
                    </span>
                </div>
                <div className="s-nav">
                    <div className="nav-count">
                        <div className="current-num">
                            <span>01</span>
                        </div>
                        <div className="pagination-sep">/</div>
                        <div className="total-pages-num">07</div>
                    </div>
                    <div className="nav-container">
                        <a className="site-title slow-scroll" href="#home">ALFREDO JUAN</a>
                        <nav className="nav-menu">
                            <ul className="nav-list">
                                <li><a href="#home">Home</a></li>
                                <li><a href="#about">About</a></li>
                                <li><a href="#services">Services</a></li>
                                <li><a href="#portfolio">Portfolio</a></li>
                                <li><a href="#track-records">Track Records</a></li>
                                <li><a href="#skills">Skills</a></li>
                                <li><a href="#contact">Contact</a></li>
                            </ul>
                        </nav>
                    </div>
                    {/*<ul className="nav-soc">*/}
                    {/*    <li><a href="#">Be.</a></li>*/}
                    {/*    <li><a href="#">In.</a></li>*/}
                    {/*    <li><a href="#">Play.</a></li>*/}
                    {/*</ul>*/}
                </div>
            </div>
        )
    }
}