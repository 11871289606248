import React, {Component} from 'react'

export default class TrackRecords extends Component {
    render() {
        return (
            <section id="track-records" className="section">
                <h2 className="title">TRACK RECORDS</h2>
                <div className="section-des">
                    <p>
                        From great power comes great responsibility, and you can say I have experienced that.
                    </p>
                </div>
                <p>
                    As I say before, I joined so many activities outside my campus life. You can check them out and
                    maybe...just maybe we have worked together before.
                </p>

                <ul className="skills-history block-right">
                    <li>
                        <span className="date">2017</span>
                        <p>
                            <strong>June 2017</strong> <br/>
                            Finished High School at SMAN 77 Jakarta.<br/><br/>

                            <strong>August 2017</strong> <br/>
                            Starts my new journey at <b>Computer Science Faculty</b> at <b>Universitas Brawijaya</b>. <br/><br/>

                            <strong>October 2017 - February 2018</strong> <br/>
                            Staff Muda Kementrian Karya Prestatif at Badan Eksekutif Mahasiswa Fakultas Ilmu Komputer.
                        </p>
                    </li>
                    <li>
                        <span className="date">2018</span>
                        <p>
                            <strong>March 2018 - January 2019</strong> <br/>
                            Staff Direktorat Jendral Implementasi Prestasi at Badan Eksekutif Mahasiswa Fakultas Ilmu
                            Komputer<br/><br/>

                            <strong>September 2018</strong> <br/>
                            Won <strong>Silver award </strong> at Silver Award International Exhibition of Inventions
                            "IEI" & World Invention and Innovation Forum "WIIF", Foshan, China. <br/><br/>

                            <strong>December 2018 - February 2019</strong> <br/>
                            Co-Coordinator of Design, Multimedia, and Documentation at Brawijaya English Tournament 2018
                        </p>
                    </li>
                    <li>
                        <span className="date">2019</span>
                        <p>
                            <strong>April 2019 - October 2019</strong><br/>
                            Coordinator of Desain, Dokumentasi, dan Multimedia at <a
                            href="https://www.instagram.com/hology_ub">House of Technology</a> 2.0.
                            <br/><br/>

                            <strong>2019</strong><br/>
                            Compete at a lot of application development competition in Indonesia such as <strong>ANFORCOM
                            2019</strong> by
                            Universitas Diponegoro and <strong>IT Today 2019</strong> by Institut Pertanian Bogor. <br/><br/>

                            <strong>November 2019</strong> <br/>
                            Won <strong>Bronze Award </strong> of International Invention & Innovative Competition
                            (INIIC
                            Series 2/2019) held at Selangor, Malaysia. <br/><br/>

                        </p>
                    </li>
                    <li>
                        <span className="date">2020</span>
                        <p>
                            <strong>Mid 2020</strong> <br/>
                            Compete at <strong>ITFEST 2020</strong> held by Universitas Sumatra Utara.<br/><br/>
                        </p>
                    </li>
                    <li>
                        <span className="date">2021</span>
                        <p>
                            <strong>January 2021</strong> <br/>
                            Graduated from Universitas Brawijaya<br/><br/>
                        </p>
                    </li>
                </ul>

            </section>
        )
    }
}