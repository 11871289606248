import React, {Component} from 'react'

export default class About extends Component {
    render() {
        return (
            <section id="about" className="section">
                <h2 className="title">ABOUT</h2>
                <div className="section-des">
                    A lone User Interface designer for mobile application and Android programmer that strive for perfections.
                </div>
                <div className="content-670">
                    <p>
                        I create beautiful user interface design and make it a reality by using various of programming
                        codes.
                        I'm interested in everything that makes people like my creation, that's why I always give my
                        best
                        for the best possible creation.
                    </p>
                </div>
                <img className="about-img block-right" data-jarallax-element="0 -40" src=""/>
                <ul className="about-info" data-threshold="0 0" data-jarallax-element="40 0">
                    <li>
                        <h6>EMAIL</h6>
                        <a href="mailto:alfrdjuan@icloud.com">hello@alfrdjuan.com</a>
                    </li>
                    <li>
                        <h6>SPECIALITY</h6>
                        <span>UI Design & Android Development</span>
                    </li>
                </ul>
                <div className="about-text content-670">
                    <p>
                        My name is Alfredo Juan. I'm from Jakarta, Indonesia and just finished my study at one of the
                        best university in Indonesia, Universitas Brawijaya. I'm studying at Universitas Brawijaya for 3
                        and half years, with GPA of <b>3.52</b> . It's hard you know? But it's surely worth it
                        to <em><b>speedrun</b></em> it.
                    </p>
                    <p>
                        I like to join some organization in order to develop myself. I already
                        joined student council since I was a freshman and community like Raion Community while
                        working there as application artist (the one who design user interface to develop).
                    </p>
                    <p>
                        I also like to join various application development competition (and won some of them). I like
                        to join the competition because I like to challenge myself and improve my skill. I've joined
                        competition from national scale to international scale and some international conference. This
                        is one of the way to develop my ability to write code and try new technologies.
                    </p>
                </div>
            </section>
        )
    }
}