import React, {Component} from "react";
import {Link} from 'react-router-dom'

export default class Portfolio extends Component {
    render() {
        return (
            <section id="portfolio" className="section">
                <h2 className="title">PORTFOLIO</h2>
                <div className="section-des">Glimpse of what have I create so far.</div>
                <p>
                    I already create some application that might be in your interest. This is just a sneak-peek of what I
                    have done. I am planning to make other apps, and will update these lists.
                </p>
                <div id="portfolio-wrapper" className="relative block-right">
                    <div className="portfolio-load-content-holder"/>
                    <div className="grid" id="portfolio-grid">
                        <div className="grid-sizer"/>
                        <div id="p-item-1" className="grid-item element-item p-one-half">
                            <Link className="item-link ajax-portfolio" to="/amanah">
                                <img src="images/amanah-big.jpg" alt=""/>
                                <div className="portfolio-text-holder">
                                    <div className="portfolio-text-wrapper">
                                        <p className="portfolio-text">Amanah</p>
                                        <p className="portfolio-cat">Smart Farms Management Mockups UI Design</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div id="p-item-2" className="grid-item element-item p-one-half">
                            <Link className="item-link ajax-portfolio" to="/iustia">
                                <img src="images/iustia-big.jpg" alt=""/>
                                <div className="portfolio-text-holder">
                                    <div className="portfolio-text-wrapper">
                                        <p className="portfolio-text">Iustia</p>
                                        <p className="portfolio-cat">Online Test Cheating Detection
                                            Using Sensors</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div id="p-item-2" className="grid-item element-item p-one-half">
                            <Link className="item-link ajax-portfolio" to="/openmind">
                                <img src="images/openmind-big.jpg" alt=""/>
                                <div className="portfolio-text-holder">
                                    <div className="portfolio-text-wrapper">
                                        <p className="portfolio-text">OpenMind</p>
                                        <p className="portfolio-cat">Trevu add-ons: Smart Traveler Tips UI Design</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div id="p-item-2" className="grid-item element-item p-one-half">
                            <Link className="item-link ajax-portfolio" to="/trevu">
                                <img src="images/trevu-big.jpg" alt=""/>
                                <div className="portfolio-text-holder">
                                    <div className="portfolio-text-wrapper">
                                        <p className="portfolio-text">Trevu</p>
                                        <p className="portfolio-cat">Muslim Traveler Companion</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <br/>
                </div>
            </section>
        )
    }
}